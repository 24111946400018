import { createSlice, current } from '@reduxjs/toolkit';
import { BaseEntity } from '../../utils/models/BaseEntity';
import { ContactCrm, FieldValue, OneLid } from '../../utils/models/OneLid';
import { PipeStatuses } from '../../utils/models/PipeAll';
import { helper } from '../../utils/helper/helper';

interface CrmOneLidState {
  tags: BaseEntity[];
  contacts: ContactCrm[];
  balance: number;
  balanceString: string;
  pipeStatusId: number;
  oldPipeStatusId: string;
  pipeId: number;
  responsibleById: number | undefined;
  leadFieldValue: FieldValue[];
  pipeName: string;
  oldPipeName: string;
  pipeStatusName: string;
  oldPipeStatusName: string;
  isChange: boolean;
  colorStatus: string;
  isAddContact: boolean;
  id: number | undefined;
  name: string;
  isMoiZvonki: boolean;
  showReasonRefusal: boolean;
  showComplete: boolean;
  clientId: number;
  clientPhone: string;
  clientName: string;
  clientIsVisible: boolean;
  openDublicateModal: boolean;
  openConfirmModal: boolean;
  dublicateClientId: number | undefined;
  isResponsible: boolean;
}

const initialState: CrmOneLidState = {
  balanceString: '0',
  tags: [],
  contacts: [],
  balance: 0,
  pipeStatusId: 0,
  oldPipeStatusId: '0',
  leadFieldValue: [],
  pipeName: '',
  responsibleById: undefined,
  isChange: false,
  pipeId: 0,
  pipeStatusName: '',
  oldPipeStatusName: '',
  colorStatus: '',
  isAddContact: false,
  id: undefined,
  name: '',
  isMoiZvonki: false,
  showReasonRefusal: false,
  showComplete: false,
  oldPipeName: '',
  clientId: -1,
  clientName: '',
  clientPhone: '',
  clientIsVisible: false,
  openDublicateModal: false,
  openConfirmModal: false,
  dublicateClientId: undefined,
  isResponsible: false
};

export const CrmOneLidSlice = createSlice({
  name: 'allHouseFilter',
  initialState,
  reducers: {
    setCrmOneLid: (
      state,
      action: {
        payload: OneLid;
      }
    ) => {
      const contacts = [...action.payload.contacts];

      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].phones.length === 0) {
          contacts[i].phones.push('');
        }
      }

      state.balance = action.payload.balance;
      state.balanceString = action.payload.balance.toLocaleString('ru');
      state.contacts = contacts;
      state.tags = action.payload.tags;
      state.pipeName = action.payload.pipeStatusResponse.pipeName || '';
      state.leadFieldValue = action.payload.fields;
      state.pipeStatusId = action.payload.pipeStatusResponse.id || 0;
      state.responsibleById = action.payload.responsibleById;
      state.isChange = false;
      state.pipeId = action.payload.pipeStatusResponse.pipeId;
      state.pipeStatusName = action.payload.pipeStatusResponse.name;
      state.colorStatus = action.payload.pipeStatusResponse.color;
      state.isAddContact = false;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.isMoiZvonki = action.payload.isMoiZvonki;
    },
    setNewLid: state => {
      state.balance = 0;
      state.balanceString = '0';
      state.contacts = [
        {
          name: '',
          fields: [],
          id: null,
          phones: ['']
        }
      ];
      state.tags = [];
      state.pipeName = '';
      state.leadFieldValue = [];
      state.pipeStatusId = 0;
      state.responsibleById = undefined;
      state.isChange = true;
      state.isAddContact = true;
      state.id = undefined;
      state.name = '';
      state.showReasonRefusal = false;
      state.showComplete = false;
    },
    setChangeLidName: (state, action: { payload: string }) => {
      state.name = action.payload;
      state.isChange = true;
    },
    setChangePipeStatus: (state, action: { payload: PipeStatuses }) => {
      state.pipeStatusId = action.payload.id || 0;
      state.pipeStatusName = action.payload.name;
      state.colorStatus = action.payload.color;
      state.pipeId = action.payload.pipeId;
      state.pipeName = action.payload.pipeName || '';
      state.showReasonRefusal = action.payload.type === 'FAILED';
      state.showComplete = action.payload.type === 'SUCCESS';
      state.isChange = true;
    },
    setChangePipeStatusClear: state => {
      state.pipeStatusId = 0;
      state.pipeStatusName = '';
      state.colorStatus = '';
      state.pipeId = 0;
      state.pipeName = '';
      state.showReasonRefusal = false;
      state.showComplete = false;
      state.isChange = false;
    },
    setchangeOldPipeStatus: (state, action: { payload: PipeStatuses }) => {
      state.oldPipeStatusId = String(action.payload.id);
      state.oldPipeStatusName = action.payload.name;
      state.oldPipeName = action.payload.pipeName || '';
    },
    setTags: (state, action: { payload: BaseEntity[] }) => {
      state.tags = action.payload;
      state.isChange = true;
    },
    setEmployeeId: (state, action: { payload: number }) => {
      state.responsibleById = action.payload;
      state.isChange = true;
      state.isResponsible = true;
    },
    setBalance: (state, action: { payload: string }) => {
      state.balance = helper.parseFloatStringToNumber(action.payload);
      state.balanceString = action.payload;
      state.isChange = true;
    },
    changeLeadsFiledValue: (
      state,
      action: {
        payload: {
          id: number;
          value: string;
          valueType?: string;
        };
      }
    ) => {
      const fields = [...state.leadFieldValue];
      let isFind = false;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === action.payload.id) {
          fields[i].value = action.payload.value;
          fields[i].valueType = action.payload.valueType;
          isFind = true;
        }
      }
      if (!isFind) {
        fields.push({
          value: action.payload.value,
          id: action.payload.id,
          valueType: action.payload.valueType
        });
      }
      state.leadFieldValue = fields;
      state.isChange = true;
    },
    changeLeadFiledVal: (
      state,
      action: {
        payload: {
          id: number;
          value: string;
          valueType?: string;
        };
      }
    ) => {
      const fields = [...state.leadFieldValue];
      let isFind = false;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === action.payload.id && fields[i].valueType === action.payload.valueType) {
          fields[i].value = action.payload.value;
          fields[i].valueType = action.payload.valueType;
          isFind = true;
        }
      }
      if (!isFind) {
        fields.push({
          value: action.payload.value,
          id: action.payload.id,
          valueType: action.payload.valueType
        });
      }
      state.leadFieldValue = fields;
      state.isChange = true;
    },
    changeLeadsFiledValueList: (
      state,
      action: {
        payload: {
          id: number;
          valueList: string[];
        };
      }
    ) => {
      const fields = [...state.leadFieldValue];
      let isFind = false;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].id === action.payload.id) {
          fields[i].valueList = action.payload.valueList;
          isFind = true;
        }
      }
      if (!isFind) {
        fields.push({
          valueList: action.payload.valueList,
          id: action.payload.id
        });
      }
      state.leadFieldValue = fields;
      state.isChange = true;
    },
    changeContactsFiledValue: (
      state,
      action: {
        payload: {
          contactId: number | null;
          id: number;
          value: string;
        };
      }
    ) => {
      const contacts = [...state.contacts];
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].id === action.payload.contactId) {
          let isFind = false;
          const values = contacts[i].fields ? [...contacts[i].fields] : [];
          for (let j = 0; j < values.length || 0; j++) {
            if (values[j].id === action.payload.id) {
              values[j].value = action.payload.value;
              isFind = true;
            }
          }
          if (!isFind) {
            values.push({
              value: action.payload.value,
              id: action.payload.id
            });
          }

          contacts[i].fields = values;
        }
      }
      state.contacts = contacts;
      state.isChange = true;
    },
    changeContactName: (
      state,
      action: {
        payload: {
          contactId: number | null;
          value: string;
        };
      }
    ) => {
      const contacts = [...state.contacts];
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].id === action.payload.contactId) {
          contacts[i].name = action.payload.value;
        }
      }
      state.contacts = contacts;
      state.isChange = true;
    },
    addContact: state => {
      const contacts = [...state.contacts];
      contacts.push({
        name: '',
        fields: [],
        id: null,
        phones: ['']
      });
      state.isAddContact = true;
      state.contacts = contacts;
      state.isChange = true;
    },
    addPhoneInsideContact: (
      state,
      action: {
        payload: {
          indexContact: number;
        };
      }
    ) => {
      let isChange = state.isChange;

      const newContacts: ContactCrm[] = [];
      const oldContacts = current(state).contacts;

      for (let i = 0; i < oldContacts.length; i++) {
        if (i !== action.payload.indexContact) {
          newContacts.push(oldContacts[i]);
        } else {
          const phones: string[] = [];
          for (let j = 0; j < oldContacts[i].phones.length; j++) {
            phones.push(oldContacts[i].phones[j]);
          }

          if (phones[phones.length - 1]) {
            phones.push('');
            isChange = true;
          }

          newContacts.push({
            ...oldContacts[i],
            phones
          });
        }
      }

      state.contacts = newContacts;
      state.isChange = isChange;
    },
    changePhoneInsideContact: (
      state,
      action: {
        payload: {
          indexContact: number;
          indexPhone: number;
          value: string;
        };
      }
    ) => {
      const newContacts: ContactCrm[] = [];
      const oldContacts = current(state).contacts;

      for (let i = 0; i < oldContacts.length; i++) {
        if (i !== action.payload.indexContact) {
          newContacts.push(oldContacts[i]);
        } else {
          const phones: string[] = [];
          for (let j = 0; j < oldContacts[i].phones.length; j++) {
            if (j !== action.payload.indexPhone) {
              phones.push(oldContacts[i].phones[j]);
            } else {
              phones.push(action.payload.value);
            }
          }

          newContacts.push({
            ...oldContacts[i],
            phones
          });
        }
      }

      state.contacts = newContacts;
      state.isChange = true;
    },
    removeContact: (state, action: { payload: number | null }) => {
      const contacts = [...state.contacts];
      state.contacts = contacts.filter(item => item.id !== action.payload);
      if (action.payload === null) {
        state.isAddContact = false;
      }
      state.isChange = true;
    },
    setOpenClientMessage: (
      state,
      action: {
        payload: { id: number; name: string; phone: string; isVisible: boolean };
      }
    ) => {
      state.clientIsVisible = action.payload.isVisible;
      state.clientId = action.payload.id;
      state.clientName = action.payload.name;
      state.clientPhone = action.payload.phone;
    },
    setOpenDublicateModal: (state, action: { payload: boolean }) => {
      state.openDublicateModal = action.payload;
    },
    setOpenConfirmModal: (state, action: { payload: boolean }) => {
      state.openConfirmModal = action.payload;
    },
    setChangeDublicateClientId: (state, action: { payload: number | undefined }) => {
      state.dublicateClientId = action.payload;
    },
    setChangeResponsible: state => {
      state.isResponsible = false;
    }
  }
});

export const {
  setCrmOneLid,
  setChangePipeStatus,
  setTags,
  setEmployeeId,
  setBalance,
  changeLeadsFiledValue,
  changeContactsFiledValue,
  changeContactName,
  addContact,
  setChangeLidName,
  setNewLid,
  addPhoneInsideContact,
  changePhoneInsideContact,
  removeContact,
  setchangeOldPipeStatus,
  changeLeadsFiledValueList,
  changeLeadFiledVal,
  setOpenDublicateModal,
  setChangeDublicateClientId,
  setOpenClientMessage,
  setOpenConfirmModal,
  setChangePipeStatusClear,
  setChangeResponsible
} = CrmOneLidSlice.actions;
export default CrmOneLidSlice.reducer;
