import { parse, stringify } from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface QueryParamsReturn<T, I> {
  searchParams: T;
  params: I | any;
  searchParamsString: string;
  appendSingle: (key: string, value: string | number | undefined) => void;
  appendMultiple: (key: string, value: string | number | undefined) => void;
  appendMultipleArray: (key: string, value: string[] | number[]) => void;
  appendMultipleDifferent: (key: string[], value: (string | number | undefined)[]) => void;
  removeAllSearchParams: () => void;
  remove: (key: string, value: string | undefined) => void;
  removeMultiple: (key: string[], value: (string | number | undefined)[]) => void;
  location: ReturnType<typeof useLocation>;
  push: ReturnType<typeof useNavigate>;
  splitUrl: string[];
  orderTable: (key: string) => void;
}

export function useQueryParam<T, I>(): QueryParamsReturn<T, I> {
  const push = useNavigate();
  const params = useParams();
  const location = useLocation();

  const appendSingle = (key: string, value: string | number | undefined) => {
    const query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    query[key] = value;
    push({ search: `?${stringify(query)}` });
  };

  const appendMultiple = (key: string, value: string | number | undefined) => {
    const query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    if (query[key]) {
      if (Array.isArray(query[key])) {
        query[key].push(value);
      } else {
        query[key] = [query[key], value];
      }
    } else {
      query[key] = [value];
    }
    push({ search: `?${stringify(query)}` });
  };

  const appendMultipleArray = (key: string, value: string[] | number[]) => {
    const query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    delete query[key];
    for (let i = 0; i < value.length; i++) {
      if (query[key]) {
        query[key] = [...query[key], value[i]];
      } else {
        query[key] = [value[i]];
      }
    }
    push({ search: `?${stringify(query)}` });
  };

  const appendMultipleDifferent = (key: string[], value: (string | number | undefined)[]) => {
    const query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    for (let i = 0; i < key.length; i++) {
      query[key[i]] = value[i];
    }
    push({ search: `?${stringify(query)}` });
  };

  const remove = useCallback(
    (key: string, value: string | undefined) => {
      let query: any = parse(window.location.search);
      if (query['page']) {
        query['page'] = 1;
      }
      if (Array.isArray(query[key])) {
        query[key] = query[key].filter((v: string) => v !== value);
      } else {
        delete query[key];
      }
      push({ search: `?${stringify(query)}` });
    },
    [push]
  );

  const removeMultiple = (key: string[], value: (string | number | undefined)[]) => {
    let query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    for (let i = 0; i < key.length; i++) {
      if (Array.isArray(query[key[i]])) {
        query[key[i]] = query[key[i]].filter((v: string) => value[i] !== v);
      } else {
        delete query[key[i]];
      }
    }
    push({ search: `?${stringify(query)}` });
  };

  const orderTable = (key: string) => {
    let query: any = parse(window.location.search);
    if (query['page']) {
      query['page'] = 1;
    }
    if (Array.isArray(query['order'])) {
      if (query['order'].includes(key)) {
        const index = query['order'].indexOf(key);
        switch (query['order'][index + 1]) {
          case 'ASC':
            query['order'][index + 1] = 'DESC';
            break;
          case 'DESC':
            query['order'].splice(index, 2);
            break;
          default:
            query['order'][index + 1] = 'ASC';
            break;
        }
      } else {
        query['order'] = [...query['order'], key, 'ASC'];
      }
    } else {
      query['order'] = [key, 'ASC'];
    }
    push({ search: `?${stringify(query)}` });
  };

  const removeAllSearchParams = () => {
    push({ search: undefined });
  };

  return {
    splitUrl: location.pathname.split('/'),
    searchParams: parse(window.location.search) as any,
    searchParamsString: window.location.search,
    params: params as any,
    orderTable,
    removeAllSearchParams,
    appendMultipleArray,
    appendMultipleDifferent,
    appendSingle,
    appendMultiple,
    removeMultiple,
    location,
    push,
    remove
  };
}
