import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../utils/constants/format';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import { message } from 'antd';
import { calculateNewTablePayments } from '../../utils/helper/createMonthlyPayments';
import { UpdateMonthlyPaymentRes } from '../../utils/models/UpdateMonthlyPaymentRes';
import { helper } from '../../utils/helper/helper';
import { roundFloatNumber } from '../../utils/helper/roundFloatNumber';
import { CurrencyItem } from '../../utils/models/Currency';

interface FlatState {
  amount: number;
  contractId: number;
  clientName: string;
  contractNumber: string;
  firstPaymentDate: string;
  monthlyPaymentPartDtos?: MonthlyPayment[];
  delay: number | null;
  delayInterval: number | null;
  sumTablePayments: number;
  residueTablePayments: number;
  currency?: CurrencyItem;
}

export const initialState: FlatState = {
  amount: 0,
  clientName: '',
  contractNumber: '',
  contractId: 0,
  firstPaymentDate: dayjs(new Date()).add(1, 'month').format(DATE_FORMAT),
  delayInterval: 1,
  delay: 6,
  sumTablePayments: 0,
  residueTablePayments: 0
};

export const monthlyPaymentsSlice = createSlice({
  name: 'monthlyPayments',
  initialState,
  reducers: {
    setFirstData: (state, action: PayloadAction<UpdateMonthlyPaymentRes>) => {
      const table: MonthlyPayment[] = [];
      let sum = action.payload.residue;
      for (let i = 0; i < action.payload.monthlyPaymentParts.length; i++) {
        table.push({
          ...action.payload.monthlyPaymentParts[i],
          amountString: action.payload.monthlyPaymentParts[i].amount.toLocaleString('ru'),
          sum
        });
        sum = roundFloatNumber(sum - action.payload.monthlyPaymentParts[i].amount);
      }
      state.contractId = action.payload.id;
      state.clientName = action.payload.clientName;
      state.contractNumber = action.payload.number;
      state.delay = action.payload.delay;
      state.amount = action.payload.residue;
      state.delayInterval = action.payload.interval;
      state.firstPaymentDate = action.payload.date;
      state.monthlyPaymentPartDtos = table;
      state.sumTablePayments = action.payload.residue;
      state.residueTablePayments = 0;
      state.currency = action.payload.currency;
    },
    setDelay: (state, action: PayloadAction<number | null>) => {
      state.delay = action.payload;
      state.delayInterval = 1;
      state.monthlyPaymentPartDtos = calculateNewTablePayments(state.amount, state.firstPaymentDate, action.payload || 0, 1);
      state.sumTablePayments = state.amount;
      state.residueTablePayments = 0;
    },
    setDelayInterval: (state, action: PayloadAction<number | null>) => {
      if (!action.payload) {
        state.delayInterval = null;
        return;
      }
      if ((state.delay || 0) % action.payload === 0) {
        state.delayInterval = action.payload;
        state.monthlyPaymentPartDtos = calculateNewTablePayments(state.amount, state.firstPaymentDate, state.delay || 0, action.payload);
        state.sumTablePayments = state.amount;
        state.residueTablePayments = 0;
        return;
      }
      const interval = action.payload - ((state.delay || 0) % action.payload);
      message.warn(
        `Interval kiritishda xatolikni oldini olish maqsadida vaqt oralig'i ${state.delay} dan ${(state.delay || 0) + interval} ga o'zgartirildi!`
      );
      state.delay = (state.delay || 0) + interval;
      state.delayInterval = action.payload;
      state.monthlyPaymentPartDtos = calculateNewTablePayments(state.amount, state.firstPaymentDate, state.delay, action.payload);
      state.sumTablePayments = state.amount;
      state.residueTablePayments = 0;
    },
    setFirstPaymentDate: (state, action: PayloadAction<string>) => {
      let table = state.monthlyPaymentPartDtos?.slice();
      const delayInterval = state.delayInterval;
      let delay = 0;
      if (Array.isArray(table)) {
        for (let i = 0; i < table.length; i++) {
          table[i].date = dayjs(action.payload, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT);
          delay += delayInterval || 0;
        }
      }
      state.monthlyPaymentPartDtos = table;
      state.firstPaymentDate = action.payload;
    },
    changeOnePaymentDate: (
      state,
      action: PayloadAction<{
        date: string;
        index: number;
      }>
    ) => {
      let table = state.monthlyPaymentPartDtos?.slice() || [];
      if (Array.isArray(table)) {
        table[action.payload.index].date = action.payload.date;
      }
      state.firstPaymentDate = table[0].date || '';
      state.monthlyPaymentPartDtos = table;
    },
    changeOnePaymentAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        index: number;
      }>
    ) => {
      const { monthlyPaymentPartDtos, amount } = current(state);

      let sum = 0;
      let residue = amount;

      const newTable: MonthlyPayment[] = [];

      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          if (i === action.payload.index) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              amount: helper.parseFloatStringToNumber(action.payload.amount),
              amountString: action.payload.amount,
              sum: residue
            });
            residue = roundFloatNumber(residue - helper.parseFloatStringToNumber(action.payload.amount));
            sum = roundFloatNumber(sum + helper.parseFloatStringToNumber(action.payload.amount));
          } else {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - newTable[i].amount);
            sum = roundFloatNumber(sum + newTable[i].amount);
          }
        }
      }

      state.monthlyPaymentPartDtos = newTable;
      state.sumTablePayments = sum;
      state.residueTablePayments = residue;
    },
    clearMonthlyPaymentsTable: state => {
      const { amount } = current(state);
      state.monthlyPaymentPartDtos = undefined;
      state.residueTablePayments = amount;
      state.sumTablePayments = 0;
    },
    addItemsTable: (
      state,
      action: PayloadAction<{
        amount: number;
        number: number;
        date: string;
      }>
    ) => {
      const { monthlyPaymentPartDtos, amount, delayInterval, delay: fullDelay } = current(state);
      const table = monthlyPaymentPartDtos ? monthlyPaymentPartDtos.slice() : [];
      let delay = 0;
      for (let i = 0; i < action.payload.number; i++) {
        table.push({
          amount: action.payload.amount,
          date: dayjs(action.payload.date, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT),
          amountString: action.payload.amount.toLocaleString('ru')
        });
        delay += 1;
      }
      let sum = 0;
      let residue = amount;
      for (let i = 0; i < table.length; i++) {
        table[i] = {
          ...table[i],
          sum: residue
        };
        residue = roundFloatNumber(residue - table[i].amount);
        sum = roundFloatNumber(sum + table[i].amount);
      }

      if (residue > 0 && (fullDelay || 1) / (delayInterval || 1) === table.length) {
        table[table.length - 1].amount = roundFloatNumber(table[table.length - 1].amount + residue);
        table[table.length - 1].amountString = roundFloatNumber(table[table.length - 1].amount + residue).toLocaleString('ru');
        sum = roundFloatNumber(sum + residue);
        residue = 0;
      }

      state.firstPaymentDate = table[0].date;
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = table;
    },
    deleteItemTable: (state, action: PayloadAction<number>) => {
      const { monthlyPaymentPartDtos, amount } = current(state);

      const newTable: MonthlyPayment[] = [];
      let sum = 0;
      let residue = amount;
      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          if (i !== action.payload) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - monthlyPaymentPartDtos[i].amount);
            sum = roundFloatNumber(sum + monthlyPaymentPartDtos[i].amount);
          }
        }
      }

      state.firstPaymentDate = newTable.length > 0 ? newTable[0].date : '';
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = newTable;
    },
    clear: state => {
      state.delayInterval = 1;
      state.delay = 6;
      state.amount = 0;
      state.firstPaymentDate = dayjs(new Date()).add(1, 'month').format(DATE_FORMAT);
      state.monthlyPaymentPartDtos = undefined;
      state.contractId = 0;
      state.sumTablePayments = 0;
      state.residueTablePayments = 0;
      state.currency = undefined;
    }
  }
});

export const {
  setFirstData,
  setDelay,
  setDelayInterval,
  setFirstPaymentDate,
  changeOnePaymentDate,
  changeOnePaymentAmount,
  clearMonthlyPaymentsTable,
  addItemsTable,
  deleteItemTable,
  clear
} = monthlyPaymentsSlice.actions;
export default monthlyPaymentsSlice.reducer;
