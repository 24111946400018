import { message } from 'antd';

import { getCurrentLanguageWord } from '../utils/helper/getCurrentLanguageWord';
import { BaseLanguageEntity } from '../utils/models/BaseLanguageEntity';

export function showMessage(messageObj: BaseLanguageEntity, isSuccess: boolean) {
  if (isSuccess) {
    message.success(getCurrentLanguageWord(messageObj));
  } else {
    message.error(getCurrentLanguageWord(messageObj));
  }
}
