import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Flat, FlatRepairedStatus } from '../../utils/models/Flat';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../utils/constants/format';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import { message } from 'antd';
import { FlatDiscount, FlatDiscountRepairedNotRepaired } from '../../utils/models/Discount';
import { changeDiscount } from '../../utils/helper/calculateDiscount';
import { calculateNewTablePayments } from '../../utils/helper/createMonthlyPayments';
import { LocalStorage } from '../../service/LocalStorage';
import { USER_KEY } from '../../utils/constants/localStorageKeys';
import { CustomFieldData } from '../../utils/models/CustomField';
import { helper } from '../../utils/helper/helper';
import { mathFloor } from '../../utils/helper/mathFloor';
import { roundFloatNumber } from '../../utils/helper/roundFloatNumber';

export interface FlatState {
  flat: Flat | null;
  repaired: FlatRepairedStatus;
  priceRepaired: number | null;
  priceNotRepaired: number | null;
  prePaymentPercent: number | null;
  selectedType: 'repaired' | 'unrepaired';
  discount: number | null;
  discountString: string;
  discountDescription?: string;
  prepaymentDate: string;
  firstPaymentDate: string;
  monthlyPaymentPartDtos?: MonthlyPayment[];
  delay: number | null;
  prepayment: number | null;
  prepaymentString: string;
  delayInterval: number | null;
  flatId: number | null;
  isUsingDiscount: boolean;
  haveDiscount: boolean;
  discountNotRepaired: FlatDiscount[];
  discountRepaired: FlatDiscount[];
  number: string;
  sumTablePayments: number;
  residueTablePayments: number;
  brokerId: number;
  changeNumber: boolean;
  fields: CustomFieldData[];
}

const initialState: FlatState = {
  prepaymentString: '',
  discountString: '',
  flat: null,
  prepayment: null,
  discount: null,
  prepaymentDate: dayjs(new Date()).format(DATE_FORMAT),
  firstPaymentDate: dayjs(new Date()).add(1, 'month').format(DATE_FORMAT),
  delayInterval: 1,
  delay: 6,
  selectedType: 'unrepaired',
  repaired: FlatRepairedStatus.BOTH,
  flatId: null,
  priceRepaired: null,
  priceNotRepaired: null,
  prePaymentPercent: null,
  isUsingDiscount: false,
  haveDiscount: false,
  discountNotRepaired: [],
  discountRepaired: [],
  number: '',
  sumTablePayments: 0,
  residueTablePayments: 0,
  brokerId: Number(LocalStorage.get(USER_KEY)?.id),
  changeNumber: false,
  fields: []
};

export const flatSlice = createSlice({
  name: 'flat',
  initialState,
  reducers: {
    setFlat: (
      state,
      action: PayloadAction<{
        flat: Flat;
        alwaysClear?: boolean;
      }>
    ) => {
      const { flat, alwaysClear } = action.payload;
      if (state.flatId !== flat.id || alwaysClear) {
        let typeFlat: FlatRepairedStatus = FlatRepairedStatus.BOTH;
        if (!flat.pricePerArea) {
          typeFlat = FlatRepairedStatus.REPAIRED;
          state.selectedType = 'repaired';
        }
        if (!flat.pricePerAreaRepaired) {
          typeFlat = FlatRepairedStatus.NOT_REPAIRED;
          state.selectedType = 'unrepaired';
        }
        if (flat.repaired) {
          typeFlat = FlatRepairedStatus.REPAIRED;
          state.selectedType = 'repaired';
        }
        state.flat = flat;
        state.repaired = typeFlat;
        state.delay = flat.delay || 6;
        state.delayInterval = 1;
        state.prepayment = flat.prepayment || null;
        state.prepaymentString = flat.prepayment.toLocaleString('ru');
        state.prePaymentPercent = flat.prepaymentPercent;
        state.priceRepaired = flat.priceRepaired;
        state.priceNotRepaired = flat.priceNotRepaired;
        state.flatId = flat.id;
        state.isUsingDiscount = false;
        state.number = '';
        state.brokerId = Number(LocalStorage.get(USER_KEY)?.id);
        state.changeNumber = false;
        state.discount = null;
        state.discountString = '';
        state.discountDescription = '';
        state.fields = [];
      }
    },
    setDelay: (state, action: PayloadAction<number | null>) => {
      state.delayInterval = 1;
      state.delay = action.payload;
    },
    setDelayInterval: (state, action: PayloadAction<number | null>) => {
      if (!action.payload) {
        state.delayInterval = null;
        return;
      }
      if ((state.delay || 0) % action.payload === 0) {
        state.delayInterval = action.payload;
        return;
      }
      const interval = action.payload - ((state.delay || 0) % action.payload);
      message.warn(
        `Interval kiritishda xatolikni oldini olish maqsadida vaqt oralig'i ${state.delay} dan ${(state.delay || 0) + interval} ga o'zgartirildi!`
      );
      state.delay = (state.delay || 0) + interval;
      state.delayInterval = action.payload;
    },
    setSelectedType: (state, action: PayloadAction<'repaired' | 'unrepaired'>) => {
      state.selectedType = action.payload;
      if (action.payload === 'repaired') {
        const prepayment = roundFloatNumber(((state.priceRepaired || 0) * (state.prePaymentPercent || 0)) / 100);
        state.prepayment = prepayment;
        state.prepaymentString = prepayment.toLocaleString('ru');
      } else {
        const prepayment = roundFloatNumber(((state.priceNotRepaired || 0) * (state.prePaymentPercent || 0)) / 100);
        state.prepayment = prepayment;
        state.prepaymentString = prepayment.toLocaleString('ru');
      }
      if (current(state).isUsingDiscount) {
        const { discount, discountDescription } = changeDiscount(current(state));
        state.discount = discount;
        state.discountString = Number(discount).toLocaleString('ru');
        state.discountDescription = discountDescription;
      }
    },
    setDiscount: (state, action: PayloadAction<string>) => {
      state.discount = helper.parseFloatStringToNumber(action.payload || '') || null;
      state.discountString = action.payload;
      const totalPrice = roundFloatNumber(
        (state.selectedType === 'repaired' ? state?.flat?.priceRepaired || 0 : state?.flat?.priceNotRepaired || 0) -
          (state?.discount || 0) -
          (state?.prepayment || 0)
      );
      if (totalPrice === 0) {
        state.delay = 0;
        state.delayInterval = 1;
      }
    },
    setDiscountDescription: (state, action: PayloadAction<string>) => {
      state.discountDescription = action.payload;
    },
    setPrepayment: (state, action: PayloadAction<string>) => {
      state.prepayment = helper.parseFloatStringToNumber(action.payload || '') || null;
      state.prepaymentString = action.payload;

      if (current(state).isUsingDiscount) {
        const { discount, discountDescription } = changeDiscount(current(state));
        state.discount = discount;
        state.discountDescription = discountDescription;
      }
      const totalPrice =
        roundFloatNumber(state.selectedType === 'repaired' ? state?.flat?.priceRepaired || 0 : state?.flat?.priceNotRepaired || 0) -
        (state?.discount || 0);
      if (totalPrice === helper.parseFloatStringToNumber(action.payload || '')) {
        state.delay = 0;
        state.delayInterval = 1;
      }
    },
    setPrepaymentDate: (state, action: PayloadAction<string>) => {
      state.prepaymentDate = action.payload;
    },
    setFirstPaymentDate: (state, action: PayloadAction<string>) => {
      let table = state.monthlyPaymentPartDtos?.slice();
      const delayInterval = state.delayInterval;
      let delay = 0;
      if (Array.isArray(table)) {
        for (let i = 0; i < table.length; i++) {
          table[i].date = dayjs(action.payload, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT);
          delay += delayInterval || 0;
        }
      }
      state.monthlyPaymentPartDtos = table;
      state.firstPaymentDate = action.payload;
    },
    changeOnePaymentDate: (
      state,
      action: PayloadAction<{
        date: string;
        index: number;
      }>
    ) => {
      let table = state.monthlyPaymentPartDtos?.slice() || [];
      if (Array.isArray(table)) {
        table[action.payload.index].date = action.payload.date;
      }
      state.monthlyPaymentPartDtos = table;
      state.firstPaymentDate = table[0].date;
    },
    setMonthlyPaymentPartDtos: state => {
      const totalPrice = mathFloor(
        (state.selectedType === 'repaired' ? state.flat?.priceRepaired || 0 : state.flat?.priceNotRepaired || 0) -
          (state.discount || 0) -
          (state.prepayment || 0),
        2
      );
      state.monthlyPaymentPartDtos = calculateNewTablePayments(totalPrice, state.firstPaymentDate, state.delay || 0, state.delayInterval || 1);
      state.sumTablePayments = totalPrice;
      state.residueTablePayments = 0;
    },
    changeOnePaymentAmount: (
      state,
      action: PayloadAction<{
        amount: string;
        index: number;
      }>
    ) => {
      const { monthlyPaymentPartDtos, selectedType, flat, discount, prepayment } = current(state);

      let sum = 0;
      let residue = roundFloatNumber(
        (selectedType === 'repaired' ? flat?.priceRepaired || 0 : flat?.priceNotRepaired || 0) - (discount || 0) - (prepayment || 0)
      );

      const newTable: MonthlyPayment[] = [];

      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          const amount = helper.parseFloatStringToNumber(action.payload.amount);
          if (i === action.payload.index) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              amount,
              amountString: action.payload.amount,
              sum: residue
            });
            residue = roundFloatNumber(residue - amount);
            sum = roundFloatNumber(sum + amount);
          } else {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - newTable[i].amount);
            sum = roundFloatNumber(sum + newTable[i].amount);
          }
        }
      }

      state.monthlyPaymentPartDtos = newTable;
      state.sumTablePayments = sum;
      state.residueTablePayments = residue;
    },
    addItemsTable: (
      state,
      action: PayloadAction<{
        amount: string;
        number: number;
        date: string;
      }>
    ) => {
      const { monthlyPaymentPartDtos, selectedType, flat, discount, prepayment, delayInterval, delay: fullDelay } = current(state);
      const table = monthlyPaymentPartDtos ? monthlyPaymentPartDtos.slice() : [];
      let delay = 0;
      for (let i = 0; i < action.payload.number; i++) {
        table.push({
          amountString: action.payload.amount,
          amount: helper.parseFloatStringToNumber(action.payload.amount),
          date: dayjs(action.payload.date, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT)
        });
        delay += 1;
      }
      let sum = 0;
      let residue = roundFloatNumber(
        (selectedType === 'repaired' ? flat?.priceRepaired || 0 : flat?.priceNotRepaired || 0) - (discount || 0) - (prepayment || 0)
      );
      for (let i = 0; i < table.length; i++) {
        table[i] = {
          ...table[i],
          sum: residue
        };
        residue = roundFloatNumber(residue - table[i].amount);
        sum = roundFloatNumber(sum + table[i].amount);
      }
      if (residue > 0 && (fullDelay || 1) / (delayInterval || 1) === table.length) {
        const amount = roundFloatNumber(table[table.length - 1].amount + residue);
        table[table.length - 1].amount = amount;
        table[table.length - 1].amountString = amount.toLocaleString('ru');
        sum = roundFloatNumber(sum + residue);
        residue = 0;
      }
      state.firstPaymentDate = table[0].date;
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = table;
    },
    deleteItemTable: (state, action: PayloadAction<number>) => {
      const { monthlyPaymentPartDtos, selectedType, flat, discount, prepayment } = current(state);

      const newTable: MonthlyPayment[] = [];
      let sum = 0;
      let residue = roundFloatNumber(
        (selectedType === 'repaired' ? flat?.priceRepaired || 0 : flat?.priceNotRepaired || 0) - (discount || 0) - (prepayment || 0)
      );
      if (Array.isArray(monthlyPaymentPartDtos)) {
        for (let i = 0; i < monthlyPaymentPartDtos.length; i++) {
          if (i !== action.payload) {
            newTable.push({
              ...monthlyPaymentPartDtos[i],
              sum: residue
            });
            residue = roundFloatNumber(residue - monthlyPaymentPartDtos[i].amount);
            sum = roundFloatNumber(sum + monthlyPaymentPartDtos[i].amount);
          }
        }
      }

      state.firstPaymentDate = newTable.length > 0 ? newTable[0].date : '';
      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentPartDtos = newTable;
    },
    clear: state => {
      state.monthlyPaymentPartDtos = [];
      state.firstPaymentDate = '';
      state.delay = null;
      state.delayInterval = null;
      state.selectedType = 'repaired';
      state.discount = null;
      state.discountDescription = '';
      state.prepayment = null;
      state.prepaymentDate = '';
      state.flatId = null;
      state.priceRepaired = null;
      state.priceNotRepaired = null;
      state.prePaymentPercent = null;
      state.isUsingDiscount = true;
      state.haveDiscount = false;
      state.discountNotRepaired = [];
      state.discountRepaired = [];
      state.number = '';
      state.repaired = FlatRepairedStatus.BOTH;
      state.residueTablePayments = 0;
      state.sumTablePayments = 0;
      state.brokerId = Number(LocalStorage.get(USER_KEY)?.id);
      state.fields = [];
      state.prepaymentString = '';
      state.discountString = '';
    },
    setIsUsingDiscount: (state, action: PayloadAction<boolean>) => {
      state.isUsingDiscount = action.payload;
      if (!action.payload) {
        state.isUsingDiscount = false;
        state.discount = null;
        state.discountDescription = '';
      } else {
        const { discount, discountDescription } = changeDiscount(current(state));
        state.discount = discount;
        state.discountDescription = discountDescription;
      }
    },
    setHaveDiscount: (state, action: PayloadAction<FlatDiscountRepairedNotRepaired>) => {
      if (action.payload.discountRepaired.length === 0) {
        state.isUsingDiscount = false;
        state.haveDiscount = false;
        state.discountRepaired = [];
        state.discountNotRepaired = [];
      } else {
        state.haveDiscount = true;
        state.discountRepaired = action.payload.discountRepaired;
        state.discountNotRepaired = action.payload.discountNotRepaired;
        if (current(state).isUsingDiscount) {
          const { discount, discountDescription } = changeDiscount(current(state));
          state.discount = discount;
          state.discountDescription = discountDescription;
        } else {
          state.discount = null;
          state.discountDescription = '';
          state.isUsingDiscount = false;
        }
      }
    },
    setPrePaymentDelayAndIsUsingDiscount: (
      state,
      action: PayloadAction<{
        delay: number;
        prePayment: string;
        isUsingDiscount: boolean;
      }>
    ) => {
      state.delay = action.payload.delay;
      state.prepayment = helper.parseFloatStringToNumber(action.payload.prePayment || '');
      state.prepaymentString = action.payload.prePayment;
      state.isUsingDiscount = action.payload.isUsingDiscount;
    },
    clearMonthlyPaymentsTable: state => {
      const { selectedType, flat, discount, prepayment } = current(state);
      state.monthlyPaymentPartDtos = undefined;
      state.residueTablePayments = roundFloatNumber(
        (selectedType === 'repaired' ? flat?.priceRepaired || 0 : flat?.priceNotRepaired || 0) - (discount || 0) - (prepayment || 0)
      );
      state.sumTablePayments = 0;
      state.firstPaymentDate = '';
    },
    setFirstContractNumber: (state, action: PayloadAction<string>) => {
      if (!state.number) {
        state.number = action.payload;
      }
    },
    setNumber: (state, action: PayloadAction<string>) => {
      state.number = action.payload;
    },
    setBroker: (state, action: PayloadAction<number>) => {
      state.brokerId = action.payload;
    },
    setChangeNumber: state => {
      if (!state.changeNumber) {
        state.changeNumber = true;
      }
    },
    setCustomField: (state, action: PayloadAction<CustomFieldData>) => {
      const fields = [...state.fields];
      const index = fields.findIndex(item => item.fieldId === action.payload.fieldId);
      if (index !== -1) {
        fields[index] = action.payload;
      } else {
        fields.push(action.payload);
      }
      state.fields = fields;
    }
  }
});

export const {
  setFlat,
  setDelay,
  setDelayInterval,
  setSelectedType,
  setDiscount,
  setPrepaymentDate,
  setDiscountDescription,
  setPrepayment,
  setMonthlyPaymentPartDtos,
  setFirstPaymentDate,
  changeOnePaymentDate,
  changeOnePaymentAmount,
  setPrePaymentDelayAndIsUsingDiscount,
  clear,
  setHaveDiscount,
  setIsUsingDiscount,
  setNumber,
  setFirstContractNumber,
  deleteItemTable,
  clearMonthlyPaymentsTable,
  addItemsTable,
  setBroker,
  setChangeNumber,
  setCustomField
} = flatSlice.actions;
export default flatSlice.reducer;
