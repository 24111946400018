export enum Permissions {
  PERMISSION_READ_FLATS = 'PERMISSION_READ_FLATS',
  PERMISSION_CREATE_BOOKING = 'PERMISSION_CREATE_BOOKING',
  PERMISSION_CREATE_CONTRACT = 'PERMISSION_CREATE_CONTRACT',
  PERMISSION_READ_BOOKINGS = 'PERMISSION_READ_BOOKINGS',
  PERMISSION_CANCEL_BOOKING = 'PERMISSION_CANCEL_BOOKING',
  PERMISSION_CREATE_BOOKING_CONTRACT = 'PERMISSION_CREATE_BOOKING_CONTRACT',
  PERMISSION_CANCEL_CONTRACT = 'PERMISSION_CANCEL_CONTRACT',
  PERMISSION_UPDATE_CONTRACT_AMOUNT = 'PERMISSION_UPDATE_CONTRACT_AMOUNT',
  PERMISSION_TRANSFER_CONTRACT = 'PERMISSION_TRANSFER_CONTRACT',
  PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT = 'PERMISSION_UPDATE_CONTRACT_MONTHLY_PAYMENT',
  PERMISSION_READ_CONTRACTS = 'PERMISSION_READ_CONTRACTS',
  PERMISSION_READ_PAYMENTS = 'PERMISSION_READ_PAYMENTS',
  PERMISSION_CREATE_PAYMENT = 'PERMISSION_CREATE_PAYMENT',
  PERMISSION_CANCEL_PAYMENT = 'PERMISSION_CANCEL_PAYMENT',
  PERMISSION_UPDATE_CLIENT = 'PERMISSION_UPDATE_CLIENT',
  PERMISSION_SEND_MESSAGE_TO_CLIENT = 'PERMISSION_SEND_MESSAGE_TO_CLIENT',
  PERMISSION_READ_CLIENTS = 'PERMISSION_READ_CLIENTS',
  PERMISSION_SEND_MESSAGE_TO_DEBTS = 'PERMISSION_SEND_MESSAGE_TO_DEBTS',
  PERMISSION_CANCEL_CONTRACT_SURCHARGE = 'PERMISSION_CANCEL_CONTRACT_SURCHARGE',
  PERMISSION_READ_DEBTS = 'PERMISSION_READ_DEBTS',
  PERMISSION_READ_MESSAGES = 'PERMISSION_READ_MESSAGES',
  PERMISSION_SEND_MESSAGE = 'PERMISSION_SEND_MESSAGE',
  PERMISSION_SETTINGS_HOUSE = 'PERMISSION_SETTINGS_HOUSE',
  PERMISSION_SETTINGS_MESSAGE = 'PERMISSION_SETTINGS_MESSAGE',
  PERMISSION_SETTINGS_INTERFACE = 'PERMISSION_SETTINGS_INTERFACE',
  PERMISSION_SETTINGS_EMPLOYEE = 'PERMISSION_SETTINGS_EMPLOYEE',
  PERMISSION_SETTINGS_DISCOUNT = 'PERMISSION_SETTINGS_DISCOUNT',
  PERMISSION_SETTINGS_COMPANY = 'PERMISSION_SETTINGS_COMPANY',
  PERMISSION_SETTINGS_TRANSFER_DATA = 'PERMISSION_SETTINGS_TRANSFER_DATA',
  PERMISSION_SETTINGS_BUILDER = 'PERMISSION_SETTINGS_BUILDER',
  PERMISSION_SETTINGS_BRANCH = 'PERMISSION_SETTINGS_BRANCH',
  PERMISSION_DEVELOPER = 'PERMISSION_DEVELOPER',
  PERMISSION_TABLE_SETTINGS = 'PERMISSION_TABLE_SETTINGS',
  PERMISSION_CHANGES = 'PERMISSION_CHANGES',
  PERMISSION_SETTINGS_PAYMENT = 'PERMISSION_SETTINGS_PAYMENT',
  PERMISSION_INTEGRATION = 'PERMISSION_INTEGRATION',
  PERMISSION_SETTINGS_AMOCRM = 'PERMISSION_SETTINGS_AMOCRM',
  PERMISSION_SHOWROOM = 'PERMISSION_SHOWROOM',
  PERMISSION_READ_STAFF = 'PERMISSION_READ_STAFF',
  PERMISSION_SETTINGS_KPI = 'PERMISSION_SETTINGS_KPI',
  PERMISSION_MOBILE = 'PERMISSION_MOBILE',
  PERMISSION_SETTINGS_AUTO_TASK = 'PERMISSION_SETTINGS_AUTO_TASK',
  PERMISSION_PAGE_CRM = 'PERMISSION_PAGE_CRM',
  PERMISSION_SETTINGS_CRM = 'PERMISSION_SETTINGS_CRM',
  PERMISSION_CHANGE_CONTRACT_RESPONSIBLE = 'PERMISSION_CHANGE_CONTRACT_RESPONSIBLE',
  PERMISSION_MONETARY_PAYMENT = 'PERMISSION_MONETARY_PAYMENT',
  PERMISSION_REALTOR = 'PERMISSION_REALTOR',
  PERMISSION_CHANGE_PASSWORD_EMPLOYEE = 'PERMISSION_CHANGE_PASSWORD_EMPLOYEE',
  PERMISSION_STATS_MAIN = 'PERMISSION_STATS_MAIN',
  PERMISSION_STATS_PAYMENT = 'PERMISSION_STATS_PAYMENT',
  PERMISSION_STATS_DEBT = 'PERMISSION_STATS_DEBT',
  PERMISSION_STATS_HOUSE = 'PERMISSION_STATS_HOUSE',
  PERMISSION_STATS_CONTRACT = 'PERMISSION_STATS_CONTRACT',
  PERMISSION_STATS_CRM_GENERAL = 'PERMISSION_STATS_CRM_GENERAL',
  PERMISSION_STATS_CRM_CALLS = 'PERMISSION_STATS_CRM_CALLS',
  PERMISSION_STATS_CRM_TASKS = 'PERMISSION_STATS_CRM_TASKS',
  PERMISSION_STATS_CRM_LEADS = 'PERMISSION_STATS_CRM_LEADS',
  PERMISSION_STATS_CRM_CONVERSION = 'PERMISSION_STATS_CRM_CONVERSION',
  PERMISSION_STATS_SALE = 'PERMISSION_STATS_SALE',
  PERMISSION_CALL_HISTORY = 'PERMISSION_CALL_HISTORY',
  PERMISSION_CRM_CHAT_BOX = 'PERMISSION_CRM_CHAT_BOX',
  PERMISSION_CRM_LEAD_IMPORT_AND_EXPORT = 'PERMISSION_CRM_LEAD_IMPORT_AND_EXPORT',
  PERMISSION_CRM_LEAD_GROUP_FUNCTION = 'PERMISSION_CRM_LEAD_GROUP_FUNCTION',
  PERMISSION_ACTIVITY_LOGS = 'PERMISSION_ACTIVITY_LOGS',
  PERMISSION_CONTRACT_PAYMENT = 'PERMISSION_CONTRACT_PAYMENT',
  PERMISSION_PAST_DATE_PAYMENT = 'PERMISSION_PAST_DATE_PAYMENT',
  PERMISSION_LEAD_TASK_ACTIONS = 'PERMISSION_LEAD_TASK_ACTIONS',
  PERMISSION_MACRO_ANALYTICS = 'PERMISSION_MACRO_ANALYTICS',
  PERMISSION_EDIT_MAIN_DASHBOARD = 'PERMISSION_EDIT_MAIN_DASHBOARD',
  PERMISSION_PAGE_DASHBOARD = 'PERMISSION_PAGE_DASHBOARD',
  PERMISSION_ONEC = 'PERMISSION_ONEC',
  PERMISSION_EXPO = 'PERMISSION_EXPO',
  PERMISSION_GIVE_DISCOUNT = 'PERMISSION_GIVE_DISCOUNT',
  PERMISSION_BOOKING_PAYMENT = 'PERMISSION_BOOKING_PAYMENT'
}
