import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../../utils/constants/format';
import { helper } from '../../utils/helper/helper';
import { roundFloatNumber } from '../../utils/helper/roundFloatNumber';
import { ClientPhone, JuridicalPersonCreate, NaturalPersonCreate } from '../../utils/models/Client';
import { ClientType } from '../../utils/models/ClientType';
import { ContractChanges } from '../../utils/models/ContractId';
import { ChangesMonthlyPayment } from '../../utils/models/ContractMothlyPayment';
import { MonthlyPayment } from '../../utils/models/CreateContract';
import { CurrencyItem } from '../../utils/models/Currency';

interface ChangesState {
  searchClient: string;
  clientType: ClientType;
  selectedClient: JuridicalPersonCreate | NaturalPersonCreate | null;
  clientPhones: ClientPhone[];
  monthlyPaymentParts?: MonthlyPayment[];
  sumTablePayments: number;
  residueTablePayments: number;
  residue: number;
  selectedContract: ContractChanges | null;
  currency?: CurrencyItem;
}

const initialState: ChangesState = {
  searchClient: '',
  clientType: ClientType.NATURAL,
  clientPhones: [
    {
      phone: '',
      active: true,
      code: 'UZ'
    }
  ],
  selectedClient: null,
  sumTablePayments: 0,
  residueTablePayments: 0,
  residue: 0,
  selectedContract: null
};

export const changesSlice = createSlice({
  name: 'changes',
  initialState,
  reducers: {
    setClientType: (state, action: PayloadAction<ClientType>) => {
      state.clientPhones = [
        {
          phone: '',
          active: true,
          code: 'UZ'
        }
      ];
      state.selectedClient = null;
      state.clientType = action.payload;
    },
    setSelectedClient: (
      state,
      action: PayloadAction<{
        data: JuridicalPersonCreate | NaturalPersonCreate | null;
        phones: ClientPhone[];
      }>
    ) => {
      state.selectedClient = action.payload.data;
      state.clientPhones = action.payload.phones;
      state.clientType = action.payload.data?.type || ClientType.NATURAL;
    },
    checkClientPhone: (state, action: PayloadAction<number>) => {
      const phones = state.clientPhones.slice();

      for (let i = 0; i < phones.length; i++) {
        phones[i].active = i === action.payload;
      }
      state.clientPhones = phones;
    },
    deleteClientPhones: (state, action: PayloadAction<number>) => {
      const { clientPhones } = current(state);
      const newClientPhones = state.clientPhones.filter((phone, index) => index !== action.payload);

      if (clientPhones[action.payload].active) {
        newClientPhones[0].active = true;
      }
      state.clientPhones = newClientPhones;
    },
    addClientPhone: state => {
      state.clientPhones.push({
        phone: '',
        active: false,
        code: 'UZ'
      });
    },
    setMonthlyPaymentParts: (state, action: PayloadAction<ChangesMonthlyPayment>) => {
      if (current(state).monthlyPaymentParts) {
        return;
      }
      let tableSum = 0;
      const table = [...action.payload.monthlyPaymentParts];

      for (let i = 0; i < table.length; i++) {
        tableSum = roundFloatNumber(tableSum + table[i]?.amount);
        table[i] = {
          ...table[i],
          amountString: table[i].amount.toLocaleString('ru')
        };
      }
      state.monthlyPaymentParts = table;
      state.sumTablePayments = tableSum;
      state.residueTablePayments = roundFloatNumber(tableSum - action.payload?.residue);
      state.residue = action.payload?.residue;
      state.currency = action.payload.currency;
    },
    clearTableMonthlyPayments: state => {
      state.monthlyPaymentParts = undefined;
    },
    deleteItemMonthlyPaymentParts: (
      state,
      action: PayloadAction<{
        index: number;
        amount: number;
      }>
    ) => {
      state.monthlyPaymentParts = state.monthlyPaymentParts?.filter((monthlyPayment, index) => index !== action.payload.index);
      state.sumTablePayments = roundFloatNumber(state.sumTablePayments - action.payload.amount);
      state.residueTablePayments = roundFloatNumber(state.residue - state.sumTablePayments);
    },
    clearTablePayments: state => {
      state.monthlyPaymentParts = undefined;
      state.sumTablePayments = 0;
      state.residueTablePayments = state.residue;
    },
    changeAllDateMonthlyPaymentsTable: (state, action: PayloadAction<string>) => {
      const table = state.monthlyPaymentParts?.slice();
      let delay = 0;

      if (Array.isArray(table)) {
        for (let i = 0; i < table.length; i++) {
          table[i].date = dayjs(action.payload, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT);
          delay += 1;
        }
      }
      state.monthlyPaymentParts = table;
    },
    changeDateMonthlyPaymentsTable: (
      state,
      action: PayloadAction<{
        index: number;
        date: string;
      }>
    ) => {
      const table = state.monthlyPaymentParts?.slice();

      if (Array.isArray(table)) {
        table[action.payload.index].date = action.payload.date;
      }
      state.monthlyPaymentParts = table;
    },
    addItemsTable: (
      state,
      action: PayloadAction<{
        amount: string;
        number: number;
        date: string;
        fullDelay: number;
        delayInterval: number;
      }>
    ) => {
      const table = state.monthlyPaymentParts ? state.monthlyPaymentParts?.slice() : [];
      let delay = 0;

      for (let i = 0; i < action.payload.number; i++) {
        table?.push({
          amount: helper.parseFloatStringToNumber(action.payload.amount),
          date: dayjs(action.payload.date, DATE_FORMAT).add(delay, 'month').format(DATE_FORMAT),
          amountString: action.payload.amount
        });
        delay += 1;
      }

      let residue = roundFloatNumber(state.residueTablePayments - helper.parseFloatStringToNumber(action.payload.amount) * action.payload.number);
      let sum = roundFloatNumber(state.sumTablePayments + helper.parseFloatStringToNumber(action.payload.amount) * action.payload.number);

      if (residue > 0 && action.payload.number === table.length) {
        table[table.length - 1].amountString = roundFloatNumber(table[table.length - 1].amount + residue).toLocaleString('ru');
        table[table.length - 1].amount = roundFloatNumber(table[table.length - 1].amount + residue);
        sum = roundFloatNumber(sum + residue);
        residue = 0;
      }

      // console.log(table)

      state.residueTablePayments = residue;
      state.sumTablePayments = sum;
      state.monthlyPaymentParts = table;
    },
    changeOneItemAmountTable: (
      state,
      action: PayloadAction<{
        index: number;
        amount: string;
      }>
    ) => {
      const table = state.monthlyPaymentParts?.slice();
      let changeSum = 0;

      if (Array.isArray(table)) {
        changeSum = roundFloatNumber(table[action.payload.index].amount - helper.parseFloatStringToNumber(action.payload.amount));
        table[action.payload.index].amount = helper.parseFloatStringToNumber(action.payload.amount);
        table[action.payload.index].amountString = action.payload.amount;
      }
      state.residueTablePayments = roundFloatNumber(state.residueTablePayments + changeSum);
      state.sumTablePayments = roundFloatNumber(state.sumTablePayments - changeSum);
      state.monthlyPaymentParts = table;
    },
    setSelectedContract: (state, action: PayloadAction<ContractChanges | null>) => {
      state.selectedContract = action.payload;
      if (!action.payload) {
        state.residue = 0;
        state.residueTablePayments = 0;
        state.sumTablePayments = 0;
        state.monthlyPaymentParts = undefined;
      }
    },
    setSearchClient: (state, action: PayloadAction<string>) => {
      state.searchClient = action.payload;
    },
    setCurrency: (state, action: PayloadAction<CurrencyItem | undefined>) => {
      state.currency = action.payload;
    },
    setClearChanges: state => {
      state.clientType = ClientType.NATURAL;
      state.clientPhones = [
        {
          phone: '',
          active: true,
          code: ''
        }
      ];
      state.selectedClient = null;
      state.monthlyPaymentParts = undefined;
      state.sumTablePayments = 0;
      state.residueTablePayments = 0;
      state.residue = 0;
      state.selectedContract = null;
      state.searchClient = '';
      state.currency = undefined;
    },
    setClientChangesPhone: (
      state,
      action: PayloadAction<{
        index: number;
        phone: string;
      }>
    ) => {
      state.clientPhones = state.clientPhones.map((item, i) =>
        i === action.payload.index
          ? {
              ...item,
              phone: `${action.payload.phone}`
            }
          : item
      );
    }
  }
});

export const {
  setClientType,
  setSelectedClient,
  checkClientPhone,
  deleteClientPhones,
  addClientPhone,
  setMonthlyPaymentParts,
  deleteItemMonthlyPaymentParts,
  clearTablePayments,
  changeAllDateMonthlyPaymentsTable,
  changeDateMonthlyPaymentsTable,
  addItemsTable,
  changeOneItemAmountTable,
  setSelectedContract,
  setClearChanges,
  setSearchClient,
  clearTableMonthlyPayments,
  setCurrency,
  setClientChangesPhone
} = changesSlice.actions;
export default changesSlice.reducer;
