import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../utils/models/User';
import { LocalStorage } from '../../service/LocalStorage';
import { TOKEN_KEY, USER_KEY } from '../../utils/constants/localStorageKeys';

const user = LocalStorage.get<User>(USER_KEY);

interface UserState {
  user: User | null;
  lifetime: number;
}

const initialState: UserState = {
  user: typeof user !== 'string' ? user : null,
  lifetime: Number(LocalStorage.get('lifetime')) || 0
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      LocalStorage.set(USER_KEY, JSON.stringify(action.payload));
      LocalStorage.set(TOKEN_KEY, action.payload.accessToken);
    },
    clearAvatar: state => {
      const user = { ...current(state.user) };
      if (user) {
        user.avatar = null;
        LocalStorage.set(USER_KEY, JSON.stringify(user));
        state.user = user as User;
      }
    },
    addAvatar: (state, action: PayloadAction<string>) => {
      const user = { ...current(state.user) };
      if (user) {
        user.avatar = action.payload;
        LocalStorage.set(USER_KEY, JSON.stringify(user));
        state.user = user as User;
      }
    },
    setLifeTime: (state, action: PayloadAction<number>) => {
      state.lifetime = action?.payload;
      LocalStorage.set('lifetime', String(action?.payload));
    }
  }
});

export const { setUser, clearAvatar, addAvatar, setLifeTime } = userSlice.actions;
export default userSlice.reducer;
