import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import ErrorBoundary from '@components/error-boundary/ErrorBoundary';
import Loading from '@components/loading/Loading';

// import ReactGA from 'react-ga';
import { useAppSelector } from '../hooks/reduxHooks';
// import { GA_TRACKING_ID } from '../utils/constants/ga_id'
import { useQueryParam } from '../hooks/useQueryParams';
// import { LocalStorage } from '../service/LocalStorage';
// import { USER_KEY } from '../utils/constants/localStorageKeys';
import { Permissions } from '../utils/constants/permissions';

import { rootPaths } from './rootPaths';
import { rootRoutes } from './rootRoutes';

const RootRouter = () => {
  const { user } = useAppSelector(state => state.userReducer);
  const { push } = useQueryParam();
  const location = useLocation();

  // useEffect(() => {
  //   ReactGA.initialize(GA_TRACKING_ID)
  // }, [])

  // useEffect(() => {
  //   if (location.pathname !== '/') {
  //     const user = LocalStorage.get(USER_KEY);

  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.set({ userId: user?.username + user?.id });
  //     ReactGA.pageview(location.pathname);
  //   } else {
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname);
  //   }
  // }, [location]);

  useEffect(() => {
    // window.history.forward()
    if (
      user?.role?.permissions?.length === 1 &&
      user?.role?.permissions[0] === Permissions.PERMISSION_CHANGE_PASSWORD_EMPLOYEE &&
      location.pathname !== rootPaths.CHANGE_PASSWORD &&
      location.pathname !== '/'
    ) {
      push(rootPaths.CHANGE_PASSWORD);
    }
  }, [location.pathname, push, user]);

  return (
    <div>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Routes>
            {rootRoutes.map(route => (
              <Route element={<route.component />} path={route.exact ? route.path : `${route.path}/*`} key={route.path} />
            ))}
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default RootRouter;
