import { DevTools, I18nextPlugin, Tolgee, withTolgee } from '@tolgee/i18next';
import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languages = ['uz', 'ru', 'en', 'tr'];
const ns = 'uysot';
const detection = { order: ['sessionStorage', 'localStorage', 'navigator'] };

function production() {
  const backend: HttpBackendOptions = {
    loadPath: import.meta.env.VITE_LANGUAGE_SOURCE_URL,
    requestOptions: {
      cache: 'no-store' // Disable caching
    }
  };

  i18n
    .use(initReactI18next)
    .use(HttpApi)
    .use(languageDetector)
    .init({ detection, supportedLngs: languages, lng: languages[0], ns, react: { useSuspense: false }, backend });

  return i18n;
}

function development() {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(I18nextPlugin())
    .init({
      apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
      projectId: import.meta.env.VITE_TOLGEE_PROJECT_ID,
      ns: ['uysot'],
      availableLanguages: languages,
      defaultLanguage: languages[0]
    });

  withTolgee(i18n, tolgee)
    .use(initReactI18next)
    .use(languageDetector)
    .init({ detection, supportedLngs: languages, ns, react: { useSuspense: false } });

  return i18n;
}

const bootstrap = import.meta.env.PROD ? production : development;

export default bootstrap();
