import { IShowroomStateFilter } from '@pages/showroom-v2/utils/models/IShowroomStateFilter';
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { FlatNumberEnum } from './AllHouseFilter';
import { LocalStorage } from '@service/LocalStorage';

interface IShowroomState {
  filter: IShowroomStateFilter;
  drawer: {
    id: number | null;
    floor: number | null;
    visible: boolean;
  };
  listSettingDrawer: boolean;
  token?: {
    companyId: number;
    xAuthToken: string;
  };
  apartments: any[];
  apartmentActiveIndex: number;
  petition: {
    visible?: boolean;
    houseName?: string;
  };
  flatNumber: keyof typeof FlatNumberEnum;
  flatCount: number;
  showroomV2: boolean;
  switchFilterStatus: boolean;
}

const initialState: IShowroomState = {
  filter: {
    page: 1,
    size: 10
  },
  drawer: {
    id: null,
    floor: null,
    visible: false
  },
  listSettingDrawer: false,
  token: {
    companyId: 0,
    xAuthToken: ''
  },
  apartments: [{}],
  apartmentActiveIndex: 0,
  petition: {
    visible: false,
    houseName: ''
  },
  flatNumber: (LocalStorage.get('flatNumber') as FlatNumberEnum) || 'number',
  flatCount: 0,
  showroomV2: (LocalStorage.get<boolean>('showroomV2') as boolean) || false,
  switchFilterStatus: false
};

export const ShowroomSlice = createSlice({
  name: 'showroom-slice',
  initialState,
  reducers: {
    setShowroomFilter: (state, action: PayloadAction<IShowroomStateFilter>) => {
      const { filter } = current(state);
      state.filter = { ...filter, ...action.payload };
    },
    setShowroomNewFilter: (state, action: PayloadAction<IShowroomStateFilter>) => {
      const { filter } = current(state);
      state.filter = {
        houseId: filter?.houseId,
        buildingIds: filter?.buildingIds,
        ...action.payload
      };
    },
    setShowroomDrawer: (
      state,
      action: PayloadAction<{
        id: number | null;
        floor: number | null;
        visible: boolean;
      }>
    ) => {
      state.drawer = { ...action.payload };
    },
    setListSettingDrawer: (state, action: PayloadAction<boolean>) => {
      state.listSettingDrawer = action.payload;
    },
    setShowroomVisualXAuthToken: (
      state,
      action: PayloadAction<{
        companyId: number;
        xAuthToken: string;
      }>
    ) => {
      state.token = action.payload;
    },
    setShowroomApartments: (state, action: any) => {
      state.apartments = action.payload;
    },
    setShowroomApartmentActive: (state, action: any) => {
      state.apartmentActiveIndex = action.payload;
    },
    setResetActiveFlat: state => {
      state.apartmentActiveIndex = 0;
      state.apartments = [{}];
      state.drawer = {
        floor: null,
        id: null,
        visible: false
      };
    },
    setShowroomPetition: (state, action: PayloadAction<{ visible?: boolean; houseName?: string }>) => {
      state.petition = action.payload;
    },
    setShowroomFlatNumber: (state, action: { payload: keyof typeof FlatNumberEnum }) => {
      state.flatNumber = action?.payload;
      LocalStorage.set('flatNumber', action?.payload);
    },
    setShowroomFlatCount: (state, action: PayloadAction<number>) => {
      state.flatCount = action?.payload;
    },
    setShowroomV2: (state, action: PayloadAction<boolean>) => {
      state.showroomV2 = action.payload;
      LocalStorage.set('showroomV2', String(action.payload));
    },
    setSwitchFilterStatus: (state, action: PayloadAction<boolean>) => {
      state.switchFilterStatus = action.payload;
    }
  }
});

export const {
  setShowroomFilter,
  setShowroomDrawer,
  setListSettingDrawer,
  setShowroomNewFilter,
  setShowroomVisualXAuthToken,
  setShowroomApartments,
  setShowroomApartmentActive,
  setShowroomPetition,
  setShowroomFlatNumber,
  setShowroomFlatCount,
  setResetActiveFlat,
  setShowroomV2,
  setSwitchFilterStatus
} = ShowroomSlice.actions;
export default ShowroomSlice.reducer;
