import { combineReducers, configureStore } from '@reduxjs/toolkit';

import allHouseFilter from './reducers/AllHouseFilter';
import bossCurrency from './reducers/BossCurrencySlice';
import changesReducer from './reducers/ChangesSlice';
import clientReducer from './reducers/ClientSlice';
import companiesReducer from './reducers/CompaniesSlice';
import companyEmployeesReducer from './reducers/CompanyEmployeesSlice';
import companyReducer from './reducers/CompanySlice';
import contractReducer from './reducers/ContractSlice';
import createContract from './reducers/CreateContractSlice';
import crmField from './reducers/CrmFieldSlice';
import crmOneLidField from './reducers/CrmOneLidSlice';
import assigmentSlice from './reducers/CrmSlice';
import crmStatistic from './reducers/CrmStatisticsSlice';
import currency from './reducers/CurrencySlice';
import debtSlice from './reducers/DebtSlice';
import flatReducer from './reducers/FlatSlice';
import googleDocViewer from './reducers/GoogleDocVieweSlice';
import houseConfigReducer from './reducers/HouseConfigSlice';
import houseReducer from './reducers/HouseSlice';
import monthlyPaymentsReducer from './reducers/MonthlyPaymentsSlice';
import notificationSlice from './reducers/notificationSlice';
import notTaskLeadNotification from './reducers/NotTaskLeadNotificationSlice';
import qrCodeModeSlice from './reducers/QrCodeModeSlice';
import sendMessageCrmSlice from './reducers/SendMessageCrmSlice';
import shaxmatkaReducer from './reducers/ShaxmatkaSlice';
import shaxmatkaV2 from './reducers/ShaxmatkaV2Slice';
import shaxmatkaV3 from './reducers/ShaxmatkaV3Slice';
import showroomModal from './reducers/ShowroomModalSlice';
import showroomSlice from './reducers/ShowroomSlice';
import taskCalendarViewSlice from './reducers/TaskCalendarViewSlice';
import taskChangeTextSlice from './reducers/TaskChangetextSlice';
import textEditorReducer from './reducers/TextEditorSlice';
import transferDataReducer from './reducers/TransferDataSlice';
import triggerSlice from './reducers/TriggerSlice';
import userReducer from './reducers/UserSlice';
import webRTCReducer from './reducers/WebRTCSlice';

const rootReducer = combineReducers({
  shaxmatkaV3,
  shaxmatkaV2,
  showroomModal,
  userReducer,
  houseReducer,
  clientReducer,
  flatReducer,
  monthlyPaymentsReducer,
  houseConfigReducer,
  shaxmatkaReducer,
  contractReducer,
  transferDataReducer,
  companiesReducer,
  companyEmployeesReducer,
  changesReducer,
  allHouseFilter,
  assigmentSlice,
  crmField,
  crmOneLidField,
  notificationSlice,
  sendMessageCrmSlice,
  triggerSlice,
  crmStatistic,
  taskCalendarViewSlice,
  createContract,
  currency,
  googleDocViewer,
  notTaskLeadNotification,
  bossCurrency,
  companyReducer,
  webRTCReducer,
  textEditorReducer,
  showroomSlice,
  debtSlice,
  qrCodeModeSlice,
  taskChangeTextSlice
});

const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
