import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyItem } from '../../utils/models/Currency';

interface CurrencyState {
  isModalOpen: boolean;
  selectedCurrency: CurrencyItem | undefined;
  mainCurrency: CurrencyItem | undefined;
  houseCurrency: CurrencyItem | undefined;
}

const initialState: CurrencyState = {
  isModalOpen: false,
  selectedCurrency: undefined,
  mainCurrency: undefined,
  houseCurrency: undefined
};

export const currencySlice = createSlice({
  name: 'currencySlice',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        data: undefined | CurrencyItem;
      }>
    ) => {
      state.isModalOpen = action.payload.isOpen;
      state.selectedCurrency = action.payload.data;
    },
    setMainCurrency: (state, action: PayloadAction<CurrencyItem | undefined>) => {
      state.mainCurrency = action.payload;
    },
    setHouseCurrency: (state, action: PayloadAction<CurrencyItem | undefined>) => {
      state.houseCurrency = action.payload;
    }
  }
});

export const { openModal, setMainCurrency, setHouseCurrency } = currencySlice.actions;
export default currencySlice.reducer;
