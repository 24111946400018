import { createSlice, current } from '@reduxjs/toolkit';

export enum FlatNumberEnum {
  number = 'number',
  rooms = 'rooms'
}

interface AllHouseFilterState {
  filter: any;
  data: any[];
  reload: boolean;
  openDrawer: {
    id: number;
    visible: boolean;
  };
  apartments: any[];
  apartmentActiveIndex: number;
  filterDefault: any;
  flatNumber: keyof typeof FlatNumberEnum;
  status: string;
  flatCount: number;
  showroomStatus: string[] | undefined;
}

const initialState: AllHouseFilterState = {
  filter: {
    rooms: [1, 2, 3, 4, 5],
    maxArea: 160,
    minArea: 0,
    maxPrice: 2000000000,
    minPrice: 1000000,
    maxPerPrice: 15000000,
    minPerPrice: 0,
    repaired: 'null',
    empty: false,
    attached: false,
    apartment: 'null',
    fields: null,
    buildingId: null
  },
  filterDefault: {
    rooms: [1, 2, 3, 4, 5],
    maxArea: 160,
    minArea: 0,
    maxPrice: 2000000000,
    minPrice: 1000000,
    maxPerPrice: 15000000,
    minPerPrice: 0,
    repaired: 'null',
    empty: false,
    attached: false,
    apartment: 'null',
    fields: null,
    buildingId: null
  },
  data: [],
  reload: true,
  openDrawer: {
    id: 0,
    visible: false
  },
  apartments: [{}],
  apartmentActiveIndex: 0,
  flatNumber: 'number',
  status: '',
  flatCount: -1,
  showroomStatus: undefined
};

export const AllHouseFilterSlice = createSlice({
  name: 'allHouseFilter',
  initialState,
  reducers: {
    setAllHouseFilter: (state, action: any) => {
      state.filter = action.payload;
    },
    setAllHouseDefaultFilter: (state, action: any) => {
      state.filterDefault = action.payload;
    },
    setAllHouseCopy: (state, action: any) => {
      if (Array.isArray(action.payload)) {
        const arr: any[] = [];
        const newData: any[] = action.payload.map((block: any) =>
          block?.map((entrance: any) =>
            entrance?.map((floor: any) =>
              floor?.map((flat: any) => {
                if (!flat?.disable) {
                  arr.push(flat?.id);
                }
                return {
                  ...flat,
                  isActive: flat?.disable ? false : flat?.isActive
                };
              })
            )
          )
        );

        state.data = newData;
      }

      // if (!arr.includes(state.openDrawer.id)) {
      //   state.openDrawer.visible = false
      //   state.openDrawer.id = 0
      // }
    },
    setHouseReload: (state, action: any) => {
      state.reload = action.payload;
    },
    setApartments: (state, action: any) => {
      state.apartments = action.payload;
    },
    setApartmentActive: (state, action: any) => {
      state.apartmentActiveIndex = action.payload;
    },
    setOpenDrawer: (state, action: any) => {
      state.openDrawer = action.payload;

      const newData: any[] = current(state.data).map((block: any) =>
        block?.map((entrance: any) =>
          entrance.map((floor: any) =>
            floor.map((flat: any) => ({
              ...flat,

              isActive: flat.id === action.payload.id
            }))
          )
        )
      );

      state.data = newData;
    },
    setFlatNumber: (state, action: { payload: keyof typeof FlatNumberEnum }) => {
      state.flatNumber = action?.payload;
    },
    setFlatStatus: (state, action: { payload: string }) => {
      state.status = action.payload;
    },
    setFlatCount: (state, action: { payload: number }) => {
      state.flatCount = action.payload;
    },
    setShowroomStatus: (state, action: { payload: string[] }) => {
      state.showroomStatus = action.payload;
    }
  }
});

export const {
  setAllHouseFilter,
  setAllHouseCopy,
  setHouseReload,
  setOpenDrawer,
  setAllHouseDefaultFilter,
  setApartments,
  setApartmentActive,
  setFlatNumber,
  setFlatStatus,
  setFlatCount,
  setShowroomStatus
} = AllHouseFilterSlice.actions;
export default AllHouseFilterSlice.reducer;
